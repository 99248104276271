/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
// require('../css/admin.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

//import '@fortawesome/fontawesome-pro/js/all.js';
//import a2lix_lib from '@a2lix/symfony-collection/src/a2lix_sf_collection';
import './../bootstrap.js';


// const $ = require('jquery');
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './components/App';
import { SlideoverProvider } from './components/SlideoverContext.js';
import { ComponentProvider } from './components/ComponentContext.js';
//const $ = require('jquery');

// create global $ and jQuery variables
//global.$ = global.jQuery = $;

// global.a2lix_lib = a2lix_lib;


document.addEventListener("DOMContentLoaded", function(e) {

    var root = document.getElementById('ui-root');

    if (root) {
        try {

            const app = createRoot(root);
            app.render( 

                    <SlideoverProvider>
                <ComponentProvider>
                        <App {...(root.dataset)} /> 
                </ComponentProvider>
                    </SlideoverProvider>
            );

        } catch (error) {
            console.error(error);
        }
    }

})