import React from 'react';
import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import Routing from './Routing';


import { useSlideoverDispatch } from './SlideoverContext.js';




export default function Dropdown(props) {

    const dispatch = useSlideoverDispatch();

    const menu = JSON.parse(props.menu);

    function classNames(...classes) {
      return classes.filter(Boolean).join(' ')
    }

    function DropdownHeader({header}) {

        if (!header) return null;

        return (

            <div className="px-4 py-3" dangerouslySetInnerHTML={{ __html: header }}></div>

        )


    }

    function handleClick(e) {

        const dataset = e.target.dataset;

        switch (dataset.target) {

            case "slideover": {

                dispatch({type: 'opened', url: dataset.url})

                break;
            }
            default:
                
                
                location.href = dataset.url;

        }

    }

    return (
             <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
            <>
                <div>
                    <Menu.Button className={ (menu.color ? 'text-white bg-' + menu.color + '-600 hover:bg-' + menu.color + '-800' : 'text-neutral-600 bg-white hover:bg-'+ menu.color +'-50') + " inline-flex leading-4 justify-center w-full rounded-md border border-ui-200 px-3 py-3 text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-ui-100 focus:ring-primary-500" }>
                        { menu.icon && <i className={(menu.label ? 'mr-2 ' : '') + 'fa-light fa-fw ' + menu.icon}></i> }
                        { menu.label && <span>{menu.label } <i className={'fa-light fa-fw fa-chevron-down'}></i></span> } 
                    </Menu.Button>
                </div>

                <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items
                    static
                    className="z-40 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >

                    <DropdownHeader header={menu.header} />
                    
                    <div className="py-1">

                    {menu.items.map((item, index) =>

                        <Menu.Item key={index}>
                            {({ active }) => ( 
                                <>

                                { item.target == "delete" ? (

                                    
                                    <form method="POST" action={item.url}>
                                        <input type="hidden" name="_method" value="DELETE" />
                                        <input type="hidden" name="_token" value={item.id} />
                                        <button
                                            type="submit"
                                            className={classNames(
                                                active ? 'bg-ui-100' : '', 
                                                item.color !== undefined ? 'text-' + item.color + '-500' : 'text-ui-500',
                                                'block w-full text-left px-4 py-2 text-sm hover:bg-ui-100'
                                                )
                                            }
                                        >
                                            { item.icon && <i className={'fa-light fa-fw mr-2 ' + item.icon}></i> }
                                            {item.label}
                                        </button>

                                    </form>
                                
                                ) : item.target == "page" ? (
                                
                                    <a
                                        href={item.url}
                                        data-title={item.title}
                                        data-description={item.description}
                                        data-width={item.width}
                                        className={classNames(
                                            active ? 'bg-ui-100' : '', 
                                            item.color !== undefined ? 'text-' + item.color + '-500' : 'text-ui-500',
                                            'block w-full text-left px-4 py-2 text-sm hover:bg-ui-100'
                                            )
                                        }
                                    >
                                        { item.icon && <i className={'fa-light fa-fw mr-2 ' + item.icon}></i> }
                                        {item.label}
                                    </a>

                                ) : (

                                    <button
                                        onClick={(e) => {handleClick(e)}}
                                        data-target={item.target}
                                        data-url={item.url}
                                        data-title={item.title}
                                        data-description={item.description}
                                        data-width={item.width}
                                        className={classNames(
                                            active ? 'bg-ui-100' : '', 
                                            item.color !== undefined ? 'text-' + item.color + '-500' : 'text-ui-500',
                                            'block w-full text-left px-4 py-2 text-sm hover:bg-ui-100'
                                            )
                                        }
                                    >
                                        { item.icon && <i className={'fa-light fa-fw mr-2 ' + item.icon}></i> }
                                        {item.label}
                                    </button>
                                
                                )}

                                </>


                            )}
                        </Menu.Item>


                    )}

                    </div>
                </Menu.Items>
            </Transition>
        </>
        )}
        </Menu>
    
    )

}
